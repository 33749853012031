/* You can add global styles to this file, and also import other style files */
@import '../node_modules/leaflet/dist/leaflet.css';

body {
  font-family: 'Inter', sans-serif;
}/* You can add global styles to this file, and also import other style files */

.legend {
  line-height: 20px;
  color: #555;
}

.legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.4;
  border-style: solid;
  border-color: black;
  border-width: 1px;
}

.info {
  padding: 6px 8px;
  background: white;
  background: rgba(255,255,255,0.8);
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  border-radius: 5px;
}
.info h4 {
  margin: 0 0 5px;
  color: #777;
}

.mat-slider-thumb-label {
  transform: rotate(45deg) !important;
  border-radius: 50% 50% 0 !important;
  width: 50px !important;
  height: 50px !important;
  top: -62px !important;
  right: -25px !important;
}

.mat-slider-thumb {
  transform: scale(0) !important;
}

.mat-slider-thumb-label-text {
  opacity: 1 !important;
  font-size: 100%;
}

/*html, body { height: 100%; }*/
/*body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }*/
